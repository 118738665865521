@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

@font-face {
  font-family: 'Nexa';
  src: url('/fonts/Nexa/Nexa-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('/fonts/Nexa/Nexa-Book.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('/fonts/Nexa/Nexa-Bold.otf');
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('/fonts/Nexa/Nexa-Heavy.otf');
  font-style: bold;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Light.ttf');
  font-style: thin;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

:root {
  /* fonts */
  --base-font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
  --font-family: 'Work Sans', var(--base-font-family);
  --secondary-font-family: 'Nexa', var(--base-font-family);
  --contact-font-family: 'Work Sans', var(--base-font-family);
  --font-monospace: 'Inconsolata', 'Consolas', 'Droid Sans Mono', 'Monaco',
    'Courier New', monospace;

  /* global */
  --background: #fff;
  --secondary-background: var(--indigo-500);
  --secondary-contact: var(--indigo-200);
  --ligth-gray: #f4f4f4;
  --danger: #ffa900;
  --error: #d91616;
  --black: #111111;
  --white: #ffffff;

  /* general */
  --primary: var(--pink-400);
  --secondary: var(--indigo-500);
  --primary-hover: var(--pink-500);

  /* Design system colors */
  /* Grey */
  --grey-50: #f5f5f5;
  --grey-100: #e0e0e0;
  --grey-200: #b1b1b1;
  --grey-300: #a0a0a0;
  --grey-400: #8f8f8f;
  --grey-500: #676767;
  --grey-600: #4d4d4d;
  --grey-700: #3c3c3c;
  --grey-800: #2b2b2b;
  --grey-900: #121212;
  --grey-medium-dark: #2f2f2f;
  --grey-medium: #b4b4b4;
  --grey-light: #e0e0e0;
  --grey-lighter: #e4e4e4;
  /* Indigo */
  --indigo-100: #ebe8f1;
  --indigo-200: #9b93af;
  --indigo-300: #574582;
  --indigo-400: #271158;
  --indigo-500: #0c0028;
  --indigo-600: #060014;
  /* Pink */
  --pink-100: #ffeff2;
  --pink-200: #f794a5;
  --pink-300: #e44963;
  --pink-400: #ef294b;
  --pink-500: #c71b39;
  --pink-600: #901f33;
  /* Blue */
  --blue-100: #eff6ff;
  --blue-200: #a0c7f9;
  --blue-300: #458eed;
  --blue-400: #1a73e8;
  --blue-500: #1059b8;
  --blue-600: #0c4a9c;
  /* Teal */
  --teal-100: #dafff8;
  --teal-200: #a8f7e9;
  --teal-300: #73e3cf;
  --teal-400: #52efd3;
  --teal-500: #3acab1;
  --teal-600: #138572;
  --teal-darker: #177766;
  /* Green */
  --green-100: #e4ffe6;
  --green-200: #bce1bf;
  --green-300: #7cc884;
  --green-400: #5cb865;
  --green-500: #298f33;
  --green-600: #197221;
  /* Orange */
  --orange-100: #ffe7d7;
  --orange-200: #ffb686;
  --orange-300: #fd9047;
  --orange-400: #ff802c;
  --orange-500: #f36e16;
  --orange-600: #d9600f;
  /* Red */
  --red-100: #ffdede;
  --red-200: #fd8383;
  --red-300: #ff2525;
  --red-400: #d91616;
  --red-500: #a71111;
  --red-600: #810505;
  /* End of design system colors */

  /* text */
  --text: #111;
  --link: var(--blue-400);
  --title-text: var(--indigo-400);
  --text-on-primary: #fff;
  --text-on-secondary: var(--teal-400);
  /* TODO: remove this var and only use --link */
  --link-on-background: var(--blue-300);

  /* grid */
  --grid-max-width: 120rem;
  --grid-gutter: 2rem;

  /* tags */
  --tag-technology: #197221;
  --tag-technology-background: #e4ffe6;
  --tag-open-finance: var(--indigo-400);
  --tag-open-finance-background: var(--indigo-100);
  --tag-fintech: #d9600f;
  --tag-fintech-background: #ffe7d7;
  --tag-security: var(--teal-600);
  --tag-security-background: var(--teal-100);
  --tag-use-cases: var(--blue-600);
  --tag-use-cases-background: var(--blue-100);
  --tag-company: var(--pink-400);
  --tag-company-background: var(--pink-100);

  /* navbar */
  --navbar-link-divisor: var(--grey-600);

  /* ExampleLogin */
  --exmaple-login-progressive-bar: #820ad1;
  --example-login-container-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  --example-login-icon-container: 1px solid #efefef;

  /* Button */
  --button-secondary-background-color: transparent;
  --button-secondary-background-color-hover: var(--pink-100);

  /* Resources */
  --new-tag-background-color: var(--teal-200);
  --new-tag-color: var(--teal-darker);
  /* Input */
  --placeholder-color: var(--grey-500);

  /* Dropdown */
  --dropdown-border-color: var(--grey-100);
  --dropdown-font-color: var(--grey-500);
  --dropdown-icon-color: var(--icon-active);
  --dropdown-hover-border-color: var(--grey-900);
  --dropdown-hover-font-color: var(--grey-500);
  --dropdown-focused-border-color: var(--primary);
  --dropdown-focused-font-color: var(--grey-500);
  --dropdown-label-font-color: var(--grey-900);
  --dropdown-label-background-color: var(--grey-50);
  --dropdown-disabled-background: var(--grey-50);
  --dropdown-disabled-font-color: var(--grey-300);
  --dropdown-disabled-border-color: var(--grey-100);
  --dropdown-disabled-icon-color: var(--icon-disabled);
  --dropdown-menu-background-color: var(--background);
  --dropdown-menu-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  --dropdown-option-hover-background-color: var(--grey-50);
  --dropdown-option-disabled-background-color: transparent;
  --drodpown-option-disabled-font-color: var(--grey-400);
  --dropdown-checkbox-option-disabled-background-color: var(--grey-50);
  --dropdown-option-font-color: var(--black);
  --dropdown-input-font-color: var(--grey-500);
  --dropdown-single-value-font-color: var(--grey-800);
  --dropdown-error-border-color: var(--red-300);
  --dropdown-error-font-color: var(--red-400);
  --dropdown-assistive-text-font-color: var(--grey-500);
  --dropdown-checkbox-disabled-background: var(--grey-50);
  --dropdown-checkbox-disabled-border: var(--grey-400);
  --dropdown-more-selected-badge: var(--grey-300);
  --icon-active: var(--black);
}

* {
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  background: var(--background);
  color: var(--text);
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  color: var(--text);
}

p {
  color: var(--text);
}

a {
  text-decoration: none;
  color: var(--link);
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hiddenXS {
  display: inherit;
}
.displayXS {
  display: none;
}

/* Animations */
/* fade in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.2s;
}

@media screen and (max-width: 768px) {
  .hiddenXS {
    display: none;
  }
  .displayXS {
    display: inherit;
  }
}
.pluggy.connector-row {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding-right: 16px;
  margin-left: -16px;
  background: transparent !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.pluggy.connector-row label {
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--black);
}
