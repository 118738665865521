.page-grid {
  margin-top: 4px !important;
  padding-top: 1rem !important;
  margin-left: 120px !important;
}
.navbar-mobile {
  display: none !important;
}
.navbar-desktop {
  display: block;
  margin-top: 4px;
  padding-top: 1rem;
  width: 100%;
}
.lp-section {
  max-width: 1440px;
  margin: auto;
}

.hr-bank {
  left: 720px;
  margin-top: 4px;
  margin-bottom: 16px;
  height: 1px;
  background-color: var(--grey-100);
  border: none;
}

.mp-logo {
  margin-top: 47px;
  margin-left: -180px;
}

.mp-logo-mobile {
  display: none;
}

.login-bt {
  padding: 0;
  margin-left: 430px;
  margin-top: 50px;
}

.login-bt-mobile {
  display: none;
}

.create-acount-bt {
  width: 135px;
  margin-top: 32px;
  left: 100px;
  margin-right: 20px;
  height: 47px;
}

.contact-bt {
  margin-top: 32px;
  left: 255px;
  text-align: initial;
}

.group-bt {
  margin-right: 108px;
  display: flex;
}

.bank-comp {
  display: flex;
}

.fb {
  margin-top: 44px;
  margin-left: -24px;
}

.connector-list {
  position: absolute;
  justify-content: space-between;
  padding: 15px;
  width: 355px;
  height: 460px;
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: -376px;
}

.bb-image {
  margin-top: 8px !important;
  margin-left: 16px !important;
}

.last-update-bank {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-left: 16px;
  margin-top: 16px;
  color: var(--grey-700);
}

.result-update-bank {
  font-family: 'Work Sans';
  font-style: normal;
  margin-top: 16px;
  margin-left: 4px;
}

.top-title-landingpage {
  font-family: 'Nexa';
  src: url('/fonts/Nexa/Nexa-Regular.otf');
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 60px;
  color: var(--indigo-400);
  width: 452px;
  height: 169px;
  margin-left: 140px;
  top: 256px;
}

.top-desc-landingpage {
  width: 453px;
  height: 120px;
  margin-left: 140px;
  margin-top: 40px;
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Light.ttf');
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--grey-800);
}

.create-account-bt-lp {
  margin-top: 32px;
  margin-left: 150px;
}

.connector-card {
  box-sizing: border-box;
  margin-top: 12px;
  width: 323px;
  height: 120px;
  background: var(--white);
  align-content: center;
  border: 1px solid var(--grey-100);
  border-radius: 4px;
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-Regular.ttf');
  font-size: 14px;
}

.gradient-class {
  height: 555px;
  margin-left: 64px;
  margin-top: -228px;
  position: relative;
  z-index: -1;
}

.landing-download-bt {
  width: 190px;
  height: 72px;
  left: 48%;
  top: 155px;
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

@media (max-width: 768px) {
  .identity-card {
    display: none !important;
  }

  .card-title-start {
    font-family: 'Nexa';
    src: url('/fonts/Nexa/Nexa-Regular.otf');
    font-size: 50px;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    margin-bottom: auto !important;
    padding-top: 120px;
    margin-left: 80px !important;
    display: flex;
  }

  .card-title-start h1,
  .card-title-start h2 {
    color: var(--indigo-400) !important;
    padding-right: 33%;
    font-size: 30px !important;
  }

  .gradient-class {
    height: 555px;
    margin-left: -304px;
    margin-top: -185px;
    position: relative;
    z-index: 0;
  }

  .login-bt {
    padding: 0;
    margin-left: 216px;
    margin-top: 50px;
  }

  .info {
    width: 86.5% !important;
    height: auto;
    margin-left: 10% !important;
    margin-top: 94px;
    background: var(--grey-50);
    border-radius: 8px;
    position: relative;
  }

  .account-comp {
    display: block !important;
    margin-left: 200px;
  }

  .download-div {
    position: absolute;
    display: flex !important;
    width: 150px !important;
    height: 72px;
    background: var(--white);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin-left: -136px !important;
    margin-bottom: 55px;
    margin-top: 480px !important;
  }

  .download-text {
    display: flex !important;
    font-family: 'Work Sans';
    src: url(/fonts/Work-Sans/WorkSans-Regular.ttf);
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 19px;
    margin-left: 25% !important;
    color: var(--pink-400);
    margin-top: 24px;
    margin-bottom: 29.5px;
  }

  .up-line {
    width: 45px;
    height: 0px;
    margin-top: 35px;
    margin-left: 2px !important;
    border: 0.5px solid var(--grey-100);
    transform: rotate(-90deg);
  }

  .trash-icon {
    margin-top: 29px;
    margin-bottom: 29.5px;
    margin-left: -5px !important;
  }

  .identity-card {
    width: 225px !important;
    height: 248px;
    background: var(--white);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin-left: 60%;
    padding-top: 24px;
  }

  .group-bt {
    margin-left: 20%;
    margin-top: 50px;
  }

  .mp-logo {
    margin-top: 47px;
    margin-left: -100px;
  }

  .mp-logo-mobile {
    display: none;
  }

  .top-title-landingpage {
    font-family: 'Nexa';
    src: url('/fonts/Nexa/Nexa-Regular.otf');
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 60px;
    color: var(--indigo-400);
    width: 320px;
    height: 169px;
    margin-left: 80px;
    top: 256px;
  }

  .top-desc-landingpage {
    width: 313px;
    height: 120px;
    margin-left: 80px;
    margin-top: 40px;
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Light.ttf');
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--grey-800);
  }

  .create-account-bt-lp {
    margin-top: 32px;
    margin-left: 150px;
  }

  .connector-card {
    display: none;
  }

  .connector-list {
    display: none !important;
  }

  .connector-list-tablet {
    position: absolute;
    display: block !important;
    justify-content: space-between;
    padding: 15px;
    width: 271px;
    height: 460px;
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-left: 64px;
  }

  .connector-card {
    display: block !important;
    box-sizing: border-box;
    width: 240px;
    height: 120px;
    background: var(--white);
    align-content: center;
    border: 1px solid var(--grey-100);
    border-radius: 4px;
    font-family: 'Work Sans';
    src: url(/fonts/Work-Sans/WorkSans-Regular.ttf);
    font-size: 14px;
  }

  .landing-download-bt {
    width: 190px;
    height: 72px;
    left: 48%;
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .line-c-card {
    width: 195px !important;
    border: 1px solid var(--grey-100);
  }

  .line-c-card-id {
    width: 223px !important;
    border: 0.5px solid var(--grey-100);
    margin-top: 24px;
  }

  .identity-comp {
    margin-left: 10px !important;
  }
}

.card-lp {
  display: flex;
  justify-content: center;
  background: var(--white);
  margin-top: 148px;
  width: 1240px;
  margin-left: 10%;
}

.card-grid {
  border: 1px solid var(--grey-100);
  width: 1240px;
  border-radius: 8px;
  box-sizing: border-box;
}

.card-title {
  font-family: 'Nexa';
  src: url('/fonts/Nexa/Nexa-Regular.otf');
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 30px;
  color: var(--indigo-400);
  margin-left: 49px;
  padding-top: 34px;
  padding-bottom: 16px;
}

.card-title-row {
  border-bottom: 1px solid var(--grey-100);
}

.arrow {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-self: center;
  margin-left: 24px;
  width: 580px;
  height: 50%;
}

.point {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-top: 2px solid var(--grey-100);
  border-right: 2px solid var(--grey-100);
  transform: rotate(45deg);
}

.card-cell-row {
  padding: 0 0 0 0 !important;
}

.card-cell {
  width: 274px;
  margin-left: 54px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.cell-right-border {
  border-right: 1px solid var(--grey-100);
}

.bt-create-bottom {
  margin-bottom: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  margin-left: 244px;
}

.connector-list-tablet {
  display: none;
}

.info {
  width: 1240px;
  height: auto;
  margin-left: 146px;
  margin-top: 94px;
  padding: 44px;
  background: var(--grey-50);
  border-radius: 8px;
  position: relative;
}

.card-title-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-title-info {
  margin-top: 0px;
  margin-bottom: -16px;
}

.card-title-info2 {
  margin-top: -8px;
  margin-bottom: 28px;
}

.card-title-info h4 {
  color: var(--indigo-400) !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.card-title-info2 h2 {
  color: var(--grey-800) !important;
  font-weight: 900;
  font-size: 32px;
  font-family: 'Nexa Heavy';
  font-style: normal;
  font-size: 32px;
  line-height: 43px;
  padding-top: -80px;
  text-align: center;
}

.start {
  margin-top: auto;
}

.card-title-start {
  position: static;
  width: 100%;
  left: 155px;
  top: 733px;
  margin-top: 120px;
  padding-bottom: 20px;
  margin-left: 160px;
  display: flex;
  margin-bottom: 20px;
}

.card-title-start h1,
.card-title-start h2 {
  color: var(--indigo-400) !important;
  padding-right: 33%;
  font-size: 50px;
  font-family: 'Nexa';
  src: url('/fonts/Nexa/Nexa-Regular.otf');
  font-weight: 900;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: left;
  width: 854px;
  height: 50px;
}

#linha-horizontal {
  width: 100%;
  margin-bottom: 44px;
  height: 1px;
  background-color: var(--grey-100);
  border: none;
  margin-top: -80px;
}

.site {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.line-c-card {
  width: 297px;
  height: 0px;
  left: 720px;
  top: 357px;
  border: 1px solid var(--grey-100);
}

.last-update-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-left: 16px;
}

.download-div {
  position: absolute;
  display: flex;
  width: 190px;
  height: 72px;
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-left: 53%;
  margin-top: 16px;
}

.download-icon {
  margin-left: 25px;
  margin-top: 27px;
  margin-bottom: 29.5px;
}

.download-text {
  display: flex !important;
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-Regular.ttf');
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 35%;
  color: var(--pink-400);
  margin-top: 24px;
  margin-bottom: 29.5px;
}

.trash-icon {
  margin-top: 29px;
  margin-bottom: 29.5px;
}

.up-line {
  width: 45px;
  height: 0px;
  margin-top: 35px;
  margin-left: 24px;
  border: 0.5px solid var(--grey-100);
  transform: rotate(-90deg);
}

.identity-card {
  position: absolute;
  width: 269px;
  height: 248px;
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-left: 53%;
  padding-top: 16px;
  margin-top: 102px;
}

.detalhe-text {
  top: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-left: 21px;
  color: var(--indigo-300);
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-Regular.ttf');
  font-style: normal;
}

.identity-comp {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 15px;
}

.label {
  color: var(--login-text);
  font-size: 14px;
  line-height: 9.38px;
  margin-left: 12px;
}

.value {
  color: var(--login-balance-text);
  font-size: 14px;
  line-height: 9.38px;
  margin-left: 4.38px;
}

.identity-icon {
  min-width: 15px;
}

.identity-comp {
  margin-left: 20px;
}

.line-c-card-id {
  width: 242px;
  border: 0.5px solid var(--grey-100);
  margin-top: 24px;
}

.link-text-cc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  margin-right: 15px;
  padding: 8px 8px 8px 12px;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-Regular.ttf');
  color: var(--blue-400);
}

.link-text-i {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 12px;
  gap: 8px;
  width: 202px;
  height: 36px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 18px;
  margin-bottom: 22px;
  margin-left: 10px;
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-Regular.ttf');
  color: var(--blue-400);
}

.account-comp {
  margin-top: -268px;
  margin-right: 50px;
}

.conected-acount-text {
  font-family: 'Work Sans';
  src: url('/fonts/Work-Sans/WorkSans-Regular.ttf');
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
  color: var(--grey-700);
}

.account-info-mobile {
  display: none;
}

.connector-list-tablet {
  display: none;
}

.mp-logo-bottom {
  position: absolute;
  width: 162.73px;
  height: 32px;
  margin-left: 160px;
}

.bottom-text-column {
  text-align: right !important;
}

.bottom-text {
  /* align-items: center; */
  align-self: flex-end;
  color: var(--grey-800);
  margin-right: 48px;
  font-style: normal;
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Light.ttf');
  font-weight: 400;
  font-size: 14px;
  line-height: 43px;
  /* background-color: red; */
  /* width: 300px; */
}
.pluggy-ai-link {
  margin-left: 8px;
}

.info > .ui.stackable.grid > .row.subtitle {
  display: flex;
  justify-content: space-around;
  font-weight: 900;
  font-size: 32px;
  line-height: 43px;
  font-family: 'Poppins';
  padding-top: 8px;
}

.info > .ui.stackable.grid > .row.subtitle > h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
}

.divider {
  border-top: var(--grey-100);
}

.ui.stackable.grid.footer-grid > .centered.two.column.row {
  padding: 38px 0 48px 0;
}

@media screen and (max-width: 500px) {
  .lp-section {
    max-width: 500px;
    margin: auto;
    overflow: hidden;
  }

  .gradient-class {
    display: none !important;
  }

  #linha-horizontal {
    width: 100%;
    margin-bottom: 44px;
    height: 1px;
    background-color: var(--grey-100);
    border: none;
    margin-top: -130px;
  }

  .start {
    margin-top: 125% !important;
  }

  .logo-mp-mobile {
    display: block !important;
  }

  .cell-right-border {
    border-bottom: 1px solid var(--grey-100);
    border-right: none;
  }

  .download-div {
    display: none !important;
  }

  .top-title-landingpage {
    font-family: 'Nexa';
    src: url('/fonts/Nexa/Nexa-Regular.otf');
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    height: 66px;
    margin-left: 0px;
  }

  .top-title-landingpage-row {
    display: flex;
    justify-content: center;
  }

  .identity-card {
    display: none !important;
  }

  .top-desc-landingpage {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Light.ttf');
    margin: 16px 0px 0px 0px;
    width: 100%;
    height: auto;
  }

  .bt-row {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .group-bt {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding: 40px 0px 0px 0px;
    gap: 10px;
    text-align: center;
  }

  .create-acount-bt {
    width: 288px;
    margin: 0px 0px 0px 0px;
    background: var(--pink-400);
    border-radius: 10px;
  }

  .contact-bt-row {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .contact-bt {
    display: flex;
    padding-right: 0px;
    margin-top: 10px;
    font-size: 14px;
    gap: 4px;
  }

  .account-info-mobile {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 200px;
  }

  .account-mobile-img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .page-grid {
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-left: 0px !important;
  }
  .navbar-desktop {
    display: none !important;
  }
  .navbar-mobile {
    display: block !important;
    width: 100%;
    margin-top: 16px;
  }

  .card-lp {
    background: var(--white);
    width: 100%;
    margin: 140px 0px 0px 0px;
    padding: 0px 16px 0px 16px;
  }

  .card-grid {
    border: 1px solid var(--grey-100);
    width: 50%;
  }

  .card-title {
    font-size: 20px;
    line-height: 20px;
    padding-top: 20px;
    margin: 0 20px 4px 20px;
  }

  .arrow {
    display: none;
  }

  .card-cell {
    width: 256px;
    margin: 16px 0 12px 0;
  }

  .mp-logo-mobile {
    display: block;
    margin-left: 20px;
  }

  .mp-logo {
    display: none;
  }

  .login-bt-mobile {
    display: block;
    margin-left: 30px;
    width: 84px;
    height: 37px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }

  .login-bt {
    display: none;
  }

  .info {
    width: 92% !important;
    padding: 4px !important;
    margin: 56px 0px 0px 16px !important;
  }

  .card-title-info {
    margin-top: 32px !important;
  }

  .card-title-info2 {
    margin-bottom: 24px !important;
  }

  .card-title-grid {
    display: flex;
    margin: 50px 16px 0px 16px;
    padding: 0px 0px 0px 0px;
    max-width: 100%;
    justify-content: flex-start;
  }

  .card-title-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Nexa';
    src: url('/fonts/Nexa/Nexa-Regular.otf');
    font-size: 15px !important;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: 0px;
    width: auto;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px;
  }

  .card-title-start h1,
  .card-title-start h2 {
    color: var(--indigo-400) !important;
    font-size: 20px !important;
    width: 100%;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .bt-create-bottom {
    text-align: center;
    margin: 0px 0px 60px 0px;
    padding: 0px 0px 0px 0px;
  }

  .footer-grid {
    margin: 28px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .site {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .mp-logo-bottom {
    position: relative;
    width: auto;
    height: auto;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }

  .connector-list-tablet {
    display: none !important;
  }

  .card-title-info h4 {
    font-size: 16px !important;
    line-height: 24px;
    text-align: center;
  }

  .card-title-info2 h2 {
    color: var(--grey-800) !important;
    font-size: 20px;
    line-height: 43px;
  }

  .bottom-text-column {
    text-align: center !important;
  }

  .bottom-text {
    align-items: center;
    color: var(--grey-800);
    margin-right: 16px;
    font-style: normal;
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Light.ttf');
    font-weight: 400;
    font-size: 14px;
    line-height: 43px;
    margin: -40px 0px 0px 0px;
    padding: -100px 0px 0px 0px !important;
    height: auto;
    width: auto;
  }

  /* When the two columns on FAQ becomes one in
  mobile view, one of the items margin top gets
  bigger then the others. This corrects it. */
  .faq-mobile-margin-correct {
    margin-top: -32px;
  }

  .info > .ui.stackable.grid > .row.subtitle > .title-doubts {
    font-size: 20px;
    line-height: 43px;
    letter-spacing: 0px;
    text-align: left;
  }
}

/* For some reason, the font size of this 
component only is different between Chrome 
and Firefox. */
@-moz-document url-prefix() {
  .card-title-info2 h2 {
    font-size: 26px;
  }

  @media screen and (max-width: 500px) {
    .card-title-info2 h2 {
      font-size: 16px;
    }
  }
}
