.oauth-body {
  background-color: #f2f3f5;
}

.oauth-page {
  margin-top: 10rem;

  .Loader {
    margin: auto;
  }

  .permissions {
    margin-top: 1rem;
    padding: 1rem;
  }

  .actions-row {
    margin-top: 20px;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .items-list-selections {
    margin-top: 1rem;
    padding: 1rem;
  }

  .items-list-selections .list .item {
    margin-top: 1rem;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 0 1px 2px #ccc;
    border: 1px solid #fff;

    .avatar {
      border: 1px solid #cecece;
    }
  }

  .items-list-selections .list .item:hover,
  .items-list-selections .list .item.active {
    border: 1px solid #676767;
  }

  .column.oauth-box {
    border-radius: 15px;
    border: 1px solid #ccc;
    background-color: #fefefe;
    padding: 5rem !important;
  }
}
