.welcome-page {
  max-width: 2560px;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  padding: 2rem 0rem;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;

  .card {
    background: var(--white);
    border: 1px solid var(--grey-100);
    border-radius: 4px;
    width: 100%;
    height: 121px;
    left: 220px;
    top: 331px;
    margin-bottom: 16px;
    margin-top: 8px;
  }
}

.welcome-page-margin-layout {
  width: 80%;
  margin-bottom: 60px;
}

.backup-component {
  border: 1px dashed var(--grey-400);
  border-radius: 4px;
  padding-left: 8px;
  gap: 1rem;
  width: 80%;
}

.backup-component-text {
  text-align: center;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--grey-900);
  margin-top: 48px;
}

/* listas de contas  */
.title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--black);
  margin-bottom: -19px;
}

.subtitle {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--grey-700);
}

.button_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 24px;
}

.al-description-text {
  margin-top: 0px;
}

.account-list-component {
  margin-top: 32px;
  padding-left: 220px;
}

.show-details {
  display: flex;
  margin-left: 796px;
  margin-top: 24px;
  margin-right: 8px;
}

.show-details-text {
  width: 116px;
  height: 16px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
  white-space: nowrap;
}

.chevron-right-icon {
  margin-left: 16px;
}

.welcome {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.cc-text-connect-bt {
  display: block !important;
}

.connect-bt {
  left: 280px;
}

.cc-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--grey-700);
  align-content: center;
}

.sp-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--grey-700);
  align-content: center;
}

.sp-description {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--grey-900);
  align-content: center;
  text-align: justify;
}

.cc-text-mobile {
  display: none !important;
}

.download-report-bt {
  display: block !important;
  margin-top: -94px;
}

.contextual-menu-mobile {
  display: none !important;
}

.bt-size {
  width: 155;
  height: 48px;
}

.header-row {
  display: flex;
  justify-content: space-between !important;
  align-items: baseline !important;
}

.description-header-row {
  padding-top: 0px !important;
  display: flex;
  justify-content: space-between !important;
  align-items: baseline !important;
}

.no-permissions-message-container {
  width: 100%;
  padding: 60px 16px;
  border: 1px dashed var(--grey-500);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-permissions-message-container .no-permissions-message {
  max-width: 536px;
  width: 100%;
  margin: auto;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .welcome-page {
    padding: 2rem 0.2rem 0rem;
  }

  .no-permissions-message-container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 550px) {
  .welcome-page {
    align-items: center;
  }

  .welcome-page-margin-layout {
    width: 90%;
  }

  .title {
    font-size: 20px;
    text-align: center;
  }

  .subtitle {
    font-family: Work Sans;
    color: var(--grey-700);
    width: 288px;
    margin-top: -16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .account-list-component {
    display: block !important;
    margin-left: 16px;
    margin-top: 20px;
    padding-left: 0;
  }

  .card {
    background: var(--white);
    border: 1px solid var(--grey-100);
    border-radius: 4px;
    width: 100%;
    height: 120px;
    border: 1px solid var(--grey-100);
    border-radius: 4px;
    margin-bottom: 4px;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .connect-bt {
    gap: 10px;
    left: -16px;
  }

  .show-details-text {
    display: none !important;
  }

  .chevron-right-icon {
    display: none !important;
  }

  .bt-size {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 28px !important;
  }

  .cc-text {
    font-size: 14px;
  }

  .sp-text {
    margin-top: 15px !important;
    font-size: 14px;
  }

  .cc-text-mobile {
    display: block !important;
    left: -158px;
    width: 140px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--grey-700);
    white-space: nowrap;
    margin-top: 88px !important;
    margin-bottom: -4px !important;
  }

  .download-report-bt {
    display: none !important;
  }

  .contextual-menu-mobile {
    display: block !important;
    margin-top: 29px;
  }

  .header-row {
    padding-top: 7px !important;
    padding-bottom: 5px !important;
  }

  .backup-component-text {
    font-size: 14px;
  }
}
